import { IIdentifiable } from "../domain/domainModels/IIdentifiable";
import { TechnicalError } from "../domain/errors/TechnicalError";
import { IConstructor } from "../domain/IConstructor";
import { IRepository } from "../domain/IRepository";
import { timestamp } from "../domain/Types";
import { DependencyInjectionUtils } from "../util/DependencyInjectionUtils";
import { Locker } from "../util/Locker";

// TODO: Document SingleRepository in puml diagram

// SingleRepository is protected by locks as it is important for data integrity to prevent creating multiple entries
export class SingleRepository<T extends IIdentifiable> {
  private uuid?: string = undefined;
  private locker: Locker = new Locker();

  constructor(
    private repository: IRepository<T>,
    private type: IConstructor<T>,
  ) {
    DependencyInjectionUtils.validateDependenciesDefined(arguments);
  }

  public async save(model: T): Promise<timestamp> {
    try {
      await this.locker.lock();
      return await this.internalSave(model);
    } finally {
      this.locker.unlock();
    }
  }

  public async updateValues(model: T, updates: Partial<T>): Promise<timestamp> {
    try {
      await this.locker.lock();
      return await this.repository.updateValues(model, updates);
    } finally {
      this.locker.unlock();
    }
  }

  public async getOrCreate(): Promise<T> {
    try {
      await this.locker.lock();
      let model = await this.internalTryGet();

      if (!model) {
        model = new this.type();
        await this.internalSave(model);
      }
      return model;
    } finally {
      this.locker.unlock();
    }
  }

  public async get(): Promise<T> {
    try {
      await this.locker.lock();
      const model = await this.internalTryGet();

      /* istanbul ignore if */ // Error can't happen in integration
      if (!model) {
        throw new TechnicalError("SingleRepository is empty using get()");
      }
      return model;
    } finally {
      this.locker.unlock();
    }
  }

  /* istanbul ignore next */ // Not used in integration
  public async getTimestamp(): Promise<timestamp> {
    try {
      await this.locker.lock();
      return await this.repository.getTimestamp();
    } finally {
      this.locker.unlock();
    }
  }

  /* istanbul ignore next */ // Not used in integration
  public async delete(): Promise<timestamp> {
    try {
      await this.locker.lock();
      return await this.repository.deleteAll();
    } finally {
      this.locker.unlock();
    }
  }

  public async tryGet(): Promise<T | undefined> {
    try {
      await this.locker.lock();
      return await this.internalTryGet();
    } finally {
      this.locker.unlock();
    }
  }

  public async internalSave(model: T): Promise<timestamp> {
    await this.guardSameUuid(model.uuid);
    return await this.repository.save(model);
  }

  public async internalTryGet(): Promise<T | undefined> {
    const models = await this.repository.getAll();

    /* istanbul ignore if */ // Error can't happen in integration
    if (models.length > 1) {
      throw new TechnicalError("SingleRepository contains multiple models");
    } else if (models.length === 1) {
      return models[0];
    } else {
      return undefined;
    }
  }

  private async guardSameUuid(modelUuid: string) {
    if (!this.uuid) {
      const model = await this.internalTryGet();

      if (!model) {
        return;
      }

      this.uuid = model.uuid;
    }
    /* istanbul ignore if */ // Error can't happen in integration
    if (this.uuid !== modelUuid) {
      throw new TechnicalError("SingleRepository can't save a second model");
    }
  }
}
