import { SingleRepository } from "../repository/SingleRepository";
import { BrowserOrNode } from "../util/BrowserOrNode";
import { DependencyInjectionUtils } from "../util/DependencyInjectionUtils";
import { TextUtils } from "../util/TextUtils";
import { ContextModel } from "./domainModels/ContextModel";

export class AutoContextService {
  // TODO: Rename to formatContextWihtoutAdd? and move to ContextService
  public static formatContext(context: string): string {
    return context.replace("@", "").toLocaleLowerCase();
  }

  private currentContext?: string = undefined;

  constructor(
    private readonly contextRepository: SingleRepository<ContextModel>,
  ) {
    DependencyInjectionUtils.validateDependenciesDefined(arguments);
  }

  public async getContext(): Promise<string> {
    if (this.currentContext === undefined) {
      this.currentContext = await this.initializeContext();
    }
    return this.currentContext;
  }

  public async setContext(context: string): Promise<string> {
    const formattedContext = AutoContextService.formatContext(context);
    this.updateContextInHtmlTitle(formattedContext);
    this.updateContextInQueryParameter(formattedContext);
    this.currentContext = formattedContext;

    const storedOrNewModel = await this.contextRepository.getOrCreate();
    if (storedOrNewModel.context != formattedContext) {
      await this.contextRepository.updateValues(storedOrNewModel, {
        context: formattedContext,
      });
    }

    return formattedContext;
  }

  public async applyAutoContext(contexts: string[]): Promise<string[]> {
    const currentContext = await this.getContext();
    if (contexts.length === 0 && currentContext) {
      return Promise.resolve([currentContext]);
    }
    return contexts;
  }

  private async initializeContext(): Promise<string> {
    /// Try get from the URL in browser. URL should take precedence
    /* istanbul ignore if */ // TODO: Test with mocking window.location.search?
    if (!BrowserOrNode.isNode()) {
      const urlParams = new URLSearchParams(window.location.search);
      const contextFromUrl = urlParams.get("context") || undefined;
      if (contextFromUrl) {
        const formattedContext =
          AutoContextService.formatContext(contextFromUrl);

        this.updateContextInHtmlTitle(formattedContext);
        return Promise.resolve(formattedContext);
      }
    }

    const context = (await this.contextRepository.getOrCreate()).context;
    this.updateContextInHtmlTitle(context);
    this.updateContextInQueryParameter(context);
    return Promise.resolve(context);
  }

  private updateContextInQueryParameter(context: string) {
    /* istanbul ignore if */ // TODO: Test with mocking window.history.replaceState?
    if (!BrowserOrNode.isNode()) {
      if (window.history.replaceState) {
        //prevents browser from storing history with each change:
        const url = new URL(location.href);
        if (context) {
          url.searchParams.set("context", context);
        } else {
          url.searchParams.delete("context");
        }
        // Replace url without reloading
        window.history.replaceState({}, "Brainsupporter", url);
      }
    }
  }

  /* istanbul ignore next */ // TODO: Test with spying document.title?
  private updateContextInHtmlTitle(context: string) {
    if (!BrowserOrNode.isNode()) {
      if (context) {
        document.title = "@" + TextUtils.capitalizeFirstLetter(context);
      } else {
        document.title = "Brainsupporter";
      }
    }
  }
}
