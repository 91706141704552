import { DependencyInjectionUtils } from "../util/DependencyInjectionUtils";
import { Notifier } from "./pubsub/Notifier";
import { Topics } from "./pubsub/Topics";

/* istanbul ignore next */ // TODO: Do an integration test for offline when all pieces are in place (reading, writing)
export class OfflineService {
  constructor(private readonly notifier: Notifier) {
    DependencyInjectionUtils.validateDependenciesDefined(arguments);
  }

  private offline = false;

  public async setOffline(): Promise<void> {
    if (!this.offline) {
      this.offline = true;
      await this.notifier.publish(Topics.OFFLINE, true);
    }
  }

  public async setOnline(): Promise<void> {
    if (this.offline) {
      this.offline = false;
      await this.notifier.publish(Topics.OFFLINE, false);
    }
  }

  public isOffline(): boolean {
    return this.offline;
  }

  public isOnline(): boolean {
    return !this.offline;
  }
}
