import * as Uuid from "uuid";

import { EnvironmentUtils } from "../../util/EnvironmentUtils";
import { Validate } from "../validators/Validate";
import { IDomainModel } from "./IDomainModel";

/* istanbul ignore next */ // TODO: Test
export class UserConfigModel implements IDomainModel {
  // Put all user config which should be encryptred here. Unecrypted data should go to UserModel
  public readonly uuid: string = Uuid.v4();
  public readonly modelVersion = 1;
  public static UserConfigModelType = "UserConfigModel";
  public readonly __type: string = UserConfigModel.UserConfigModelType;

  private enableLogging?: boolean = undefined;
  public getEnableLogging(): boolean {
    if (this.enableLogging === undefined) {
      // Default off for production and on for others.
      return !EnvironmentUtils.isProduction();
    }
    return this.enableLogging!;
  }
  public setEnableLogging(value?: boolean) {
    return (this.enableLogging = value);
  }

  private recentContextDays?: number = undefined;
  public getRecentContextDays(): number {
    if (this.recentContextDays === undefined) {
      return 7;
    }
    return this.recentContextDays!;
  }
  public setRecentContextDays(value?: number) {
    return (this.recentContextDays = value);
  }

  public static autoCompleteConfigValues = {
    enableLogging: ["true", "false", "default"],
    recentContextDays: [],
  };

  public static configProperties = Object.keys(
    UserConfigModel.autoCompleteConfigValues,
  );

  protected static expectedProperties = [
    "modelVersion",
    "__type",
    "uuid",
  ].concat(UserConfigModel.configProperties);

  constructor(init?: Partial<UserConfigModel>) {
    Object.assign(this, init);
  }

  protected validateExpectedProperties() {
    Validate.expectedProperties(this, UserConfigModel.expectedProperties);
  }

  public validate() {
    this.validateExpectedProperties();
    Validate.Uuidv4(this.uuid);
    Validate.number(this.modelVersion);
    Validate.stringNotEmpty(this.__type);

    if (this.enableLogging !== undefined) {
      Validate.boolean(this.enableLogging);
    }
    if (this.recentContextDays !== undefined) {
      Validate.numberZeroOrPositive(this.recentContextDays);
    }
  }
}
