export class Topics {
  public static readonly CLEAR_FEEDBACK = "CLEAR_FEEDBACK";
  public static readonly CONFIG_CHANGED = "CONFIG_CHANGED";
  public static readonly EXECUTE_COMMAND = "EXECUTE_COMMAND";
  public static readonly FUNCTIONAL_ERROR = "FUNCTIONAL_ERROR";
  public static readonly LOG_OUT = "LOG_OUT";
  public static readonly OFFLINE = "OFFLINE";
  public static readonly REFRESH_IF_NEEDED = "REFRESH_IF_NEEDED";
  public static readonly SYNCING = "SYNCING";
  public static readonly UPDATE_UI = "UPDATE_UI";
}
