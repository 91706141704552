import { DependencyInjectionUtils } from "../util/DependencyInjectionUtils";
import { DomainEventModel } from "./events/DomainEventModel";
import { IRepository } from "./IRepository";
import { DomainEventBus } from "./pubsub/DomainEventBus";

export class EventStore {
  private currentlyReplayingEvents: readonly DomainEventModel[] = [];

  private eventCounter = 0;

  constructor(
    private readonly eventBus: DomainEventBus,
    private readonly eventRepo: IRepository<DomainEventModel>,
  ) {
    DependencyInjectionUtils.validateDependenciesDefined(arguments);
  }

  public async eventHandler(event: DomainEventModel): Promise<void> {
    // Skip saving events which are being replayed.
    // This is save as events are append only. This also still allows saving new events during replay
    if (!this.currentlyReplayingEvents.find((i) => i.uuid === event.uuid)) {
      // TODO: It is possible a new event fires here while eventCounter = 0 during initial load.
      // Example Initial load after logout (no cache) plus fast event
      // Solve this by implementing a event buffer and delay saving events until the sync is finished.

      event.sequenceNumber = this.getNextEventCounter();
      await this.eventRepo.save(event);
    }
  }

  public async replayEvents(
    events?: readonly DomainEventModel[],
  ): Promise<void> {
    /* istanbul ignore next */ // TODO Cover getAll wihtin?
    this.currentlyReplayingEvents = events ?? (await this.eventRepo.getAll());

    this.eventCounter = this.currentlyReplayingEvents.length; // TODO: Test if this is correct +1 bug
    const sortableEvents = this.currentlyReplayingEvents.slice();

    sortableEvents.sort((event1, event2) => {
      if (event1.eventDateTime != event2.eventDateTime) {
        return event1.eventDateTime - event2.eventDateTime;
      }
      return event1.sequenceNumber - event2.sequenceNumber;
    });

    this.eventBus.disableSubscriberCheck();
    for (const event of sortableEvents) {
      await this.eventBus.publishEvent(event);
    }
    this.eventBus.enableSubscriberCheck();
    this.currentlyReplayingEvents = [];
  }

  public startSavingEvents(): void {
    this.eventBus.wildcardSubscribe((e) =>
      this.eventHandler(e as DomainEventModel),
    );
  }

  private getNextEventCounter(): number {
    return ++this.eventCounter;
  }
}
