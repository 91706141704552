import { DomainEventModel } from "../events/DomainEventModel";
import { Notifier } from "./Notifier";

export type eventSubscriber = (event: DomainEventModel) => Promise<void>;

// Consider RxJs when usecases arise where we need to add a lot of features here.

export class DomainEventBus extends Notifier<DomainEventModel> {
  constructor(private subscriberCheckDisabled: boolean = false) {
    super();
  }

  public async publishEvent(event: DomainEventModel): Promise<void> {
    // check if number of wildcard subscribers is 1
    // so we know at least eventStore is subscribed
    /* istanbul ignore next */ // can't be tested in integration, because eventStore is always subscribed
    if (
      !this.subscriberCheckDisabled &&
      this.wildcardSubscribers.length !== 1
    ) {
      throw new Error(
        `Wildcard subscribers for events should be 1 but is ${this.wildcardSubscribers.length}. ` +
          "EventStore is probably not subscribed and data is not being saved.",
      );
    }

    // Validate events before publishing
    event.validate();

    const topic = event.getTopic();

    await super.publish(topic, event);
  }

  public disableSubscriberCheck(): void {
    this.subscriberCheckDisabled = true;
  }

  public enableSubscriberCheck(): void {
    this.subscriberCheckDisabled = false;
  }
}
