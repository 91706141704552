import { LogService } from "brainsupporter-core/lib/logging/LogService";

import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { ApplicationInsightsService } from "./ApplicationInsightsService";

/* istanbul ignore next */ // Coverage can be improved
export class ApplicationInsightsLogService extends LogService {
  protected override logMessageSuffix = "";

  public override async logDebug(
    error: Error,
    showInUi: boolean = false,
  ): Promise<void> {
    if (ApplicationInsightsService.enableLogging) {
      ApplicationInsightsService.appInsights.trackException({
        exception: error,
        severityLevel: SeverityLevel.Verbose,
      });
    }
    await super.logDebug(error, showInUi);
  }

  public override async logDebugMessage(
    message: string,
    showInUi: boolean = false,
  ): Promise<void> {
    if (ApplicationInsightsService.enableLogging) {
      ApplicationInsightsService.appInsights.trackTrace({
        message: message,
        severityLevel: SeverityLevel.Verbose,
      });
    }

    await super.logDebugMessage(message, showInUi);
  }

  public override async logInfo(
    error: Error,
    showInUi: boolean = false,
  ): Promise<void> {
    if (ApplicationInsightsService.enableLogging) {
      ApplicationInsightsService.appInsights.trackException({
        exception: error,
        severityLevel: SeverityLevel.Information,
      });
    }
    await super.logInfo(error, showInUi);
  }

  public override async logInfoMessage(
    message: string,
    showInUi: boolean = false,
  ): Promise<void> {
    if (ApplicationInsightsService.enableLogging) {
      ApplicationInsightsService.appInsights.trackTrace({
        message: message,
        severityLevel: SeverityLevel.Information,
      });
    }
    await super.logInfoMessage(message, showInUi);
  }

  public override async logWarning(
    error: Error,
    showInUi: boolean = false,
  ): Promise<void> {
    if (ApplicationInsightsService.enableLogging) {
      ApplicationInsightsService.appInsights.trackException({
        exception: error,
        severityLevel: SeverityLevel.Warning,
      });
    }
    await super.logWarning(error, showInUi);
  }

  public override async logWarningMessage(
    message: string,
    showInUi: boolean = false,
  ): Promise<void> {
    if (ApplicationInsightsService.enableLogging) {
      ApplicationInsightsService.appInsights.trackTrace({
        message: message,
        severityLevel: SeverityLevel.Warning,
      });
    }

    await super.logWarningMessage(message, showInUi);
  }

  public override async logError(
    error: Error,
    showInUi: boolean = false,
  ): Promise<void> {
    if (ApplicationInsightsService.enableLogging) {
      ApplicationInsightsService.appInsights.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    }
    await super.logError(error, showInUi);
  }

  public override async logErrorMessage(
    message: string,
    showInUi: boolean = true,
  ): Promise<void> {
    if (ApplicationInsightsService.enableLogging) {
      ApplicationInsightsService.appInsights.trackTrace({
        message: message,
        severityLevel: SeverityLevel.Error,
      });
    }

    await super.logErrorMessage(message, showInUi);
  }
}
