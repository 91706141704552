import { SingleRepository } from "../../../repository/SingleRepository";
import { DependencyInjectionUtils } from "../../../util/DependencyInjectionUtils";
import { ModelCloner } from "../../../util/ModelCloner";
import { TextUtils } from "../../../util/TextUtils";
import { AutoCompleteService } from "../../AutoCompleteService";
import { IModel } from "../../domainModels/IModel";
import { UserConfigModel } from "../../domainModels/UserConfigModel";
import { Notifier } from "../../pubsub/Notifier";
import { Topics } from "../../pubsub/Topics";
import { Mutable } from "../../Types";
import { Validate } from "../../validators/Validate";
import { IExecutableBotCommand } from "../IBotCommand";
import { IBotCommandResult } from "../IBotCommandResult";

export class ConfigBotCommand implements IExecutableBotCommand {
  public static staticCommandName = "config";

  public readonly commandName = ConfigBotCommand.staticCommandName;
  public readonly description = "Read or change configuration";
  public readonly smartButtonText = "Config";
  public readonly argumentDescription =
    "\nconfig enableLogging [true|false|default (currently false)]" +
    "\nconfig recentContextDays [number|default (currently 7)]";

  constructor(
    private readonly configRepository: SingleRepository<UserConfigModel>,
    private readonly autoCompleteService: AutoCompleteService,
    private readonly notifier: Notifier,
  ) {
    DependencyInjectionUtils.validateDependenciesDefined(arguments);
  }

  public async execute(input: string): Promise<IBotCommandResult> {
    let feedback = "";
    let saveChanges = false;

    const config = await this.configRepository.getOrCreate();

    const clonedConfigModel = ModelCloner.clone(
      config,
    ) as Mutable<UserConfigModel>;

    // TODO: This can be generalized to a more generic type checker using Validate later  when adding more
    if (!input) {
      // Print out current settings
      for (const prop of UserConfigModel.configProperties) {
        feedback +=
          prop +
          " " +
          (config as IModel)["get" + TextUtils.capitalizeFirstLetter(prop)]() +
          "\n";
      }
    } else {
      const parts = input.split(" ");
      const configOption = parts[0];
      const configValue = parts[1];

      // When extending this: Loop configProperties and generalize types
      if (
        parts.length >= 2 &&
        TextUtils.compareIgnoreCase(configOption, "enableLogging")
      ) {
        if (TextUtils.compareIgnoreCase(configValue, "true")) {
          clonedConfigModel.setEnableLogging(true);
          saveChanges = true;
          feedback += "Setting " + configOption + " to true";
        } else if (TextUtils.compareIgnoreCase(configValue, "false")) {
          clonedConfigModel.setEnableLogging(false);
          saveChanges = true;
          feedback += "Setting " + configOption + " to false";
        } else if (TextUtils.compareIgnoreCase(configValue, "default")) {
          clonedConfigModel.setEnableLogging(undefined);
          feedback += "Setting " + configOption + " to default";
        } else {
          feedback += configValue + " should be true, false or default";
        }
      } else if (
        parts.length === 2 &&
        TextUtils.compareIgnoreCase(configOption, "recentContextDays")
      ) {
        if (Validate.isNumber(configValue) && Number(configValue) >= 0) {
          const param = Number(configValue);
          clonedConfigModel.setRecentContextDays(param);
          saveChanges = true;
          feedback += "Setting " + configOption + " to " + param;
        } else if (TextUtils.compareIgnoreCase(configValue, "default")) {
          clonedConfigModel.setRecentContextDays(undefined);
          saveChanges = true;
          feedback += "Setting " + configOption + " to default";
        } else {
          feedback += configValue + " should be zero or a positive number";
        }
      } else {
        feedback += "didn't understand that";
      }
    }

    if (saveChanges) {
      const updatedModel = clonedConfigModel as UserConfigModel;
      await this.configRepository.save(updatedModel);
      await this.notifier.publish(Topics.CONFIG_CHANGED);
    }

    return {
      commandName: this.commandName,
      feedback,
    };
  }

  // Can't test this in integration
  /* istanbul ignore next */ // TODO: Unittest
  public async getAutoCompleteKeywords(
    commandInput: string,
  ): Promise<string[]> {
    let keywords: string[] = [];

    if (commandInput.includes("enableLogging")) {
      if (commandInput.split(" ").length <= 3) {
        keywords = UserConfigModel.autoCompleteConfigValues["enableLogging"];
      }
    } else if (commandInput.includes("recentContextDays")) {
      keywords = [];
    } else {
      if (commandInput.split(" ").length <= 2) {
        keywords = UserConfigModel.configProperties;
      }
    }

    return this.autoCompleteService.getAutoCompleteKeywords(
      commandInput,
      keywords,
    );
  }
}
