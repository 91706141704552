import * as Uuid from "uuid";

import { ModelCloner } from "../../util/ModelCloner";
import { epoch } from "../Types";
import { Validate } from "../validators/Validate";
import { IDomainModel } from "./IDomainModel";

export class ProjectModel implements IDomainModel {
  public readonly modelVersion = 3;
  public readonly __type: string = "ProjectModel";

  public readonly uuid: string = Uuid.v4();
  public readonly project: string = "";
  public readonly outcomes: string[] = [];
  public readonly completed: boolean = false;
  public readonly completionDateTime?: epoch;
  public readonly creationDateTime?: epoch;

  protected static expectedProperties = [
    "modelVersion",
    "__type",
    "uuid",
    "project",
    "outcomes",
    "completed",
    "completionDateTime",
    "creationDateTime",
  ];

  protected validateExpectedProperties() {
    Validate.expectedProperties(this, ProjectModel.expectedProperties);
  }

  constructor(init?: Partial<ProjectModel>) {
    Object.assign(this, init);
  }

  public cloneUpdatedValues(projectModel: ProjectModel): ProjectModel {
    return ModelCloner.updateValues(this as ProjectModel, {
      project: projectModel.project,
      outcomes: projectModel.outcomes,
      completed: projectModel.completed,
      completionDateTime: projectModel.completionDateTime,
      creationDateTime: projectModel.creationDateTime,
    });
  }

  public validate() {
    this.validateExpectedProperties();
    Validate.Uuidv4(this.uuid);
    Validate.number(this.modelVersion);
    Validate.string(this.project);
    Validate.stringArray(this.outcomes);
    Validate.boolean(this.completed);
    Validate.optionalEpoch(this.completionDateTime);
    Validate.optionalEpoch(this.creationDateTime);
  }
}
