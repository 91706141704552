export class Locker {
  private locked: boolean;
  private waiting: (() => void)[];

  constructor() {
    this.locked = false;
    this.waiting = [];
  }

  /**
   * Acquires the lock. If the lock is already acquired, it waits until the lock is released.
   */
  async lock(): Promise<void> {
    if (!this.locked) {
      this.locked = true;
      return;
    }

    // Create a promise that resolves when it's this caller's turn.
    /* istanbul ignore next */ // Doesn't happen in integration
    return new Promise((resolve) => {
      this.waiting.push(resolve);
    });
  }

  /**
   * Releases the lock. If there are any queued waiters, the next one gets the lock.
   */
  unlock(): void {
    /* istanbul ignore if */ // Doesn't happen in integration
    if (this.waiting.length > 0) {
      // Resolve the next waiting promise to allow the next caller to acquire the lock.
      const next = this.waiting.shift();
      if (next) {
        next();
      }
    } else {
      // No one is waiting, so unlock.
      this.locked = false;
    }
  }
}
