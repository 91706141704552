import { EnvironmentUtils } from "brainsupporter-core/lib/util/EnvironmentUtils";
import { Component } from "react";
import Version from "../Version/Version";
import "./Footer.css";

type FooterProps = {
  initialLoadPerformance: string;
};

class Footer extends Component<FooterProps, unknown> {
  constructor(props: FooterProps) {
    super(props);
  }

  override render() {
    const year = new Date().getFullYear();
    return (
      <footer>
        <hr></hr>
        <ul>
          <li className="version">
            <Version />
            {EnvironmentUtils.isProduction()
              ? ""
              : " (" + this.props.initialLoadPerformance + ")"}
          </li>
          <li>
            <a href="/release-notes.html">Release notes</a>
          </li>
          <li>
            <a href="/privacy-policy.html">Privacy policy</a>
          </li>
          <li>
            <div className="link-icon">
              <a
                href="https://chat.whatsapp.com/K8qCASCDUG5HmnVnsNNtPw"
                target="_blank"
              >
                <img
                  src="/whatsapp-icon-96x96.png"
                  alt="whatsapp icon"
                  title="Whatsapp"
                />
              </a>
            </div>
          </li>
          <li>
            <div className="link-icon">
              <a
                href="https://github.com/Brainsupporter/Issues/issues"
                target="_blank"
              >
                <img src="/github-mark.png" alt="github icon" title="Issues" />
              </a>
            </div>
          </li>
          <li className="copyright">&copy; {year} Brainsupporter</li>
        </ul>
      </footer>
    );
  }
}

export default Footer;
