import * as Uuid from "uuid";

import { IRepository } from "../domain/IRepository";
import { IIdentifiable } from "../domain/domainModels/IIdentifiable";
import { BrowserOrNode } from "../util/BrowserOrNode";
import { ApiRepository } from "./ApiRepository";
import { IPageResult } from "./IPageResult";
import { PagedJsonModelRepository } from "./PagedJsonModelRepository";

/* istanbul ignore file */ // Not used during integration test when azure is turned off.
export class PagedApiRepository<T extends IIdentifiable>
  extends ApiRepository<T>
  implements IRepository<T>
{
  public static readonly APP_CACHE_COUNT = 0; // Increase this when a change in the code requires invalidating all caches

  private static readonly cacheKeyPrefix = "api-repository-cache-key-";

  public override async getAll(): Promise<readonly T[]> {
    const headers = await this.getHeaders();

    let clientRepoCacheCount: number = -1;

    let page = 1;
    let models: T[] = [];
    let pageResult: IPageResult<T>;
    do {
      const url =
        this.repositoryUri + "/page/" + page + "?c=" + this.getUriCacheKey();
      const pageJson = await this.restUtils.get(url, headers);
      pageResult = (await pageJson.json()) as IPageResult<T>;

      // Initialize on the first hit, cache or not
      if (clientRepoCacheCount === -1) {
        clientRepoCacheCount = pageResult.cacheCount;
      }

      if (pageResult.cacheCount != clientRepoCacheCount) {
        // The cache count has been changed. This could be the last page one higher which is not cached when the server changed
        // Any other discontinuity means something is wrong
        // Discard results sofar and try again with the new cache key
        // This is not much of a performance issue as the old (invalid) results came from the http cache
        this.resetCacheKey();
        return await this.getAll();
      }

      models = models.concat(pageResult.modelsInPage);
      page++;
    } while (
      pageResult.modelsInPage.length === PagedJsonModelRepository.PageSize
    );

    return models;
  }

  private getUriCacheKey() {
    return PagedApiRepository.APP_CACHE_COUNT + "." + this.getCacheKey();
  }

  /* istanbul ignore next */ // spied away and node and browser  specific code
  private getCacheKey(): string {
    if (BrowserOrNode.isNode()) {
      return this.nodeClientRepoCacheKey;
    }

    const cacheKey = window.localStorage.getItem(
      this.getLocalStorageCachekey(),
    );
    if (!cacheKey) {
      return this.resetCacheKey();
    }

    return cacheKey;
  }

  private nodeClientRepoCacheKey = "";

  /* istanbul ignore next */ // spied away and node and browser  specific code
  private resetCacheKey() {
    const randomKey = this.getRandomCacheKey();

    if (BrowserOrNode.isNode()) {
      this.nodeClientRepoCacheKey = randomKey;
      return randomKey;
    }

    window.localStorage.setItem(this.getLocalStorageCachekey(), randomKey);
    return randomKey;
  }

  private getRandomCacheKey() {
    return Uuid.v4().slice(0, 8);
  }

  /* istanbul ignore next */ // TODO: Test
  private getLocalStorageCachekey(): string {
    return PagedApiRepository.cacheKeyPrefix + this.type;
  }

  public static clearCacheKeys() {
    const keys = Object.keys(localStorage);
    for (const key of keys) {
      if (key.startsWith(this.cacheKeyPrefix)) {
        localStorage.removeItem(key);
      }
    }
  }
}
