import { TextUtils } from "../../util/TextUtils";
import { AutoContextService } from "../AutoContextService";
import { ProjectModel } from "../domainModels/ProjectModel";
import { SmartCaptureModel } from "./SmartCaptureModel";

export class SmartParser {
  // TODO: Bug: htTps://brainsupporter.com does not parse to an url
  // TODO: Bug: dit is een beetje $raar als input zonder + doet $raar verdwijnen.

  private static readonly urlRegex = "http:\\/\\/|https:\\/\\/";

  private static readonly whitespace = "\\s";
  private static readonly whitespaceOrStart = `(${SmartParser.whitespace}|^)`;
  private static readonly contextRegex = `${SmartParser.whitespaceOrStart}@`;
  private static readonly projectRegex = `${SmartParser.whitespaceOrStart}\\+`;
  private static readonly outcomeRegex = `${SmartParser.whitespaceOrStart}\\$`;
  private static readonly tagRegex = `${SmartParser.whitespaceOrStart}\\#`;

  public parseCaptureText(text: string): SmartCaptureModel {
    const allTokens = new RegExp(
      `(${SmartParser.contextRegex}|${SmartParser.projectRegex}|${SmartParser.outcomeRegex}|${SmartParser.urlRegex}|${SmartParser.tagRegex})`,
    );

    const task = TextUtils.extractTextFragmentUntilAnyToken(text, allTokens);
    const contexts = this.parseContext(
      text,
      SmartParser.contextRegex,
      allTokens,
    );
    const projects = this.removeEmptyValues(
      TextUtils.extractMultipleTextFragmentsWithoutTheirTokens(
        text,
        new RegExp(SmartParser.projectRegex),
        allTokens,
      ),
    );
    const outcomes = this.removeEmptyValues(
      TextUtils.extractMultipleTextFragmentsWithoutTheirTokens(
        text,
        new RegExp(SmartParser.outcomeRegex),
        allTokens,
      ),
    );
    const tags = this.removeEmptyValues(
      TextUtils.extractMultipleTextFragmentsWithoutTheirTokens(
        text,
        new RegExp(SmartParser.tagRegex),
        allTokens,
      ),
    );

    const urls = this.parseUrls(text, allTokens);

    return new SmartCaptureModel(
      task,
      contexts,
      projects,
      tags,
      outcomes,
      urls,
    );
  }

  public parseProjectText(text: string): ProjectModel {
    const allTokens = new RegExp(
      `(${SmartParser.projectRegex}|${SmartParser.outcomeRegex})`,
    );
    const project = TextUtils.extractTextFragmentUntilAnyToken(text, allTokens);
    const outcomes = this.removeEmptyValues(
      TextUtils.extractMultipleTextFragmentsWithoutTheirTokens(
        text,
        new RegExp(SmartParser.outcomeRegex),
        allTokens,
      ),
    );

    return new ProjectModel({ project, outcomes });
  }

  private parseContext(text: string, contextRegex: string, allTokens: RegExp) {
    const contexts = this.removeEmptyValues(
      TextUtils.extractMultipleTextFragmentsWithoutTheirTokens(
        text,
        new RegExp(contextRegex),
        allTokens,
      ),
    );

    return contexts.map((context) => {
      return AutoContextService.formatContext(context);
    });
  }

  private parseUrls(text: string, allTokens: RegExp) {
    const urlRegex = new RegExp(SmartParser.urlRegex);
    const urls = TextUtils.extractMultipleTextFragmentsIncludingTheirTokens(
      text,
      urlRegex,
      allTokens,
    );
    return urls;
  }

  private removeEmptyValues(text: string[]): string[] {
    return text.filter((str) => str.trim() != "");
  }
}
