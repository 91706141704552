import * as JSZip from "jszip";

import { EventMigrator } from "../../../migrations/events/EventMigrator";
import { ProjectTxtRepository } from "../../../repository/ProjectTxtRepository";
import { TodoTxtRepository } from "../../../repository/TodoTxtRepository";
import { DependencyInjectionUtils } from "../../../util/DependencyInjectionUtils";
import { FileInMemoryHelper } from "../../../util/FileInMemoryHelper";
import { ZipUtils } from "../../../util/ZipUtils";
import { ProjectModel } from "../../domainModels/ProjectModel";
import { TaskModel } from "../../domainModels/TaskModel";
import { EncryptionPasswordService } from "../../EncryptionPasswordService";
import { FunctionalError } from "../../errors/FunctionalError";
import { DomainEventModel } from "../../events/DomainEventModel";
import { EventStore } from "../../EventStore";
import { IRepository } from "../../IRepository";
import { ProjectService } from "../../ProjectService";
import { SynchronisationService } from "../../SynchronisationService";
import { TaskService } from "../../TaskService";
import { IExecutableBotCommand } from "../IBotCommand";
import { IBotCommandResult } from "../IBotCommandResult";
import { ExportBotQuery } from "../queries/ExportBotQuery";

export class ImportBotCommand implements IExecutableBotCommand {
  public static staticCommandName = "import";
  public readonly commandName = ImportBotCommand.staticCommandName;
  public readonly description =
    "Import a previous exported zip, or a zip in todo-txt format";
  public readonly smartButtonText = "Import zipfile";
  public readonly argumentDescription = "zip file with export";

  constructor(
    private readonly taskView: IRepository<TaskModel>,
    private readonly domainEventRepo: IRepository<DomainEventModel>,
    private readonly encryptionPasswordService: EncryptionPasswordService,
    private readonly projectView: IRepository<ProjectModel>,
    private readonly taskService: TaskService,
    private readonly projectService: ProjectService,
    private readonly eventStore: EventStore,
    private readonly synchronisationService: SynchronisationService,
  ) {
    DependencyInjectionUtils.validateDependenciesDefined(arguments);
  }

  public async execute(
    filePath: string,
    zipData: Uint8Array,
  ): Promise<IBotCommandResult> {
    let feedback = "";
    if (!zipData) {
      // Ask to read zipdata through client actions
      return {
        callBackCommand: this.commandName,
        callBackParam: filePath,
        commandName: this.commandName,
        feedback,
        confirmation: true,
      };
    } else {
      await this.import(zipData);
      feedback = "imported " + filePath;
    }
    return {
      commandName: this.commandName,
      feedback,
      confirmation: true,
    };
  }

  public async import(zipData: Uint8Array): Promise<void> {
    let zip!: JSZip;
    let events!: readonly DomainEventModel[];

    try {
      // ! Add a transaction when available
      zip = await ZipUtils.loadAsync(zipData);

      // Unzipping this data should be safe as we validate used data
      events = await this.getEvents(zip);

      // Validate before we delete
      this.validateEvents(events);
    } catch (error) {
      FunctionalError.throwFunctionalError(
        "This does not seem to be a valid brainsupporter export",
        error,
      );
    }

    if (events.length > 0) {
      await this.domainEventRepo.deleteAll();
      await this.encryptionPasswordService.resetSaltAndKeys();

      await this.domainEventRepo.import(events);
      await this.synchronisationService.reloadEvents();
    } else {
      // TODO: Import of todotxt is broken for brainsupporter-noevents.export.1658856533.zip: Validation error on uuidV4
      let importedTasks!: readonly TaskModel[];
      let importedProjects!: readonly ProjectModel[];

      try {
        importedTasks = await this.getTasks(zip);
        importedProjects = await this.getProjects(zip);

        // Validate before we delete
        this.validateTasks(importedTasks);
        this.validateProjects(importedProjects);
      } catch (error) /* istanbul ignore next TODO: write test for invalide data in todotxt */ {
        FunctionalError.throwFunctionalError(
          "This does not seem to be a valid brainsupporter export",
          error,
        );
      }

      await this.domainEventRepo.deleteAll();
      await this.encryptionPasswordService.resetSaltAndKeys();
      await this.synchronisationService.reloadEvents(); // Empty at this point

      await this.addAllProjects(importedProjects);
      await this.addAllTasks(importedTasks);
    }
  }

  // Can't test this in integration
  /* istanbul ignore next */
  public async getAutoCompleteKeywords(
    /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
    commandInput: string,
  ): Promise<string[]> {
    return Promise.resolve([]);
  }

  private async getTasks(zip: JSZip): Promise<readonly TaskModel[]> {
    const unzippedTaskText = await ZipUtils.unzipFile(
      zip,
      ExportBotQuery.todoTxtTaskExportFilePath,
    );
    const fileInMemory = new FileInMemoryHelper();
    await fileInMemory.saveTextFile(unzippedTaskText);
    const todoTxtRepository = new TodoTxtRepository(fileInMemory);

    return todoTxtRepository.getAll();
  }

  private async getProjects(zip: JSZip): Promise<readonly ProjectModel[]> {
    const unzippedProjectText = await ZipUtils.unzipFile(
      zip,
      ExportBotQuery.todoTxtProjectExportFilePath,
    );
    const fileInMemory = new FileInMemoryHelper();
    await fileInMemory.saveTextFile(unzippedProjectText);
    const projectTxtRepository = new ProjectTxtRepository(fileInMemory);

    return projectTxtRepository.getAll();
  }

  private async getEvents(zip: JSZip): Promise<readonly DomainEventModel[]> {
    const eventsFolder = ExportBotQuery.domainEventsExportFilePath;
    const events: DomainEventModel[] = [];
    const folder = zip.folder(eventsFolder); // Fail safe that can never happen
    /* istanbul ignore next */
    if (!folder) {
      return [];
    }

    await ZipUtils.forEach(folder, async (filename) => {
      const fileContent = await ZipUtils.unzipFile(zip, filename);
      const untypedModel = JSON.parse(fileContent);
      events.push(untypedModel);
    });

    // Sort so they are imported oldest first
    events.sort((event1, event2) => {
      return event1.sequenceNumber - event2.sequenceNumber;
    });

    return events;
  }

  private async addAllTasks(importedTasks: readonly TaskModel[]) {
    for (const task of importedTasks) {
      await this.taskService.add(task);
    }
  }

  private async addAllProjects(importedProjects: readonly ProjectModel[]) {
    for (const project of importedProjects) {
      await this.projectService.saveProject(project);
    }
  }

  private validateEvents(events: readonly DomainEventModel[]) {
    // To validate we have to migrate the events first, validation is the last step.
    const eventMigrator = new EventMigrator();
    eventMigrator.migrate(events);
  }

  private validateTasks(importedTasks: readonly TaskModel[]) {
    for (const task of importedTasks) {
      task.validate();
    }
  }

  private validateProjects(importedProjects: readonly ProjectModel[]) {
    for (const project of importedProjects) {
      project.validate();
    }
  }
}
