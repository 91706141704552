export class FunctionalError extends Error {
  public static IsFunctionalError(error: unknown): boolean {
    return error instanceof FunctionalError;
  }

  public static toStringOrThrow(e: Error): string {
    /* istanbul ignore else */ // No unexpected errors in integrationtest possible
    if (FunctionalError.IsFunctionalError(e)) {
      return e.message;
    } else {
      // If failed to catch, throwing it higher.
      throw e;
    }
  }

  public static throwFunctionalError(
    functionalErrorMessage: string,
    error: unknown,
  ): void {
    const funcError = new FunctionalError(functionalErrorMessage);
    funcError.InnerError = error as Error;
    throw funcError;
  }

  public InnerError?: Error = undefined;
}
