import { ConfigurationManager } from "../config/ConfigurationManager";
import { TechnicalError } from "../domain/errors/TechnicalError";
import { Notifier } from "../domain/pubsub/Notifier";
import { BrowserOrNode } from "../util/BrowserOrNode";
import { DependencyInjectionUtils } from "../util/DependencyInjectionUtils";
import { EnvironmentUtils } from "../util/EnvironmentUtils";

export type uiLogSubscriber = (param: uiLog) => Promise<void>;
export type uiLog = {
  enrichedMessage: string;
  level: string;
};

/* eslint-disable @typescript-eslint/no-explicit-any */
export interface IConsoleLog {
  debug(message?: any, ...optionalParams: any[]): void;
  error(message?: any, ...optionalParams: any[]): void;
  info(message?: any, ...optionalParams: any[]): void;
  log(message?: any, ...optionalParams: any[]): void;
  warn(message?: any, ...optionalParams: any[]): void;
}

/* istanbul ignore next */ // No integration coverage on (error) logging
export class LogService {
  private uiNotifier = new Notifier<uiLog>();
  private uiTopic = "uiTopic";

  protected logger: IConsoleLog = console;
  protected logMessageSuffix = "\n\n";

  // To access statically. This requires the class to be used as a singleton (one instance in de root)
  // Use as little as possible and only when DI is not feasable in static methods
  private static instance: LogService | undefined; // Could also be a subclass
  public static getInstance() {
    if (this.instance) {
      return this.instance;
    } else {
      throw new TechnicalError("LogService.instance undefined");
    }
  }

  public static setInstance(instance: LogService | undefined) {
    this.instance = instance;
  }

  constructor(protected readonly configurationManager: ConfigurationManager) {
    DependencyInjectionUtils.validateDependenciesDefined(arguments);
  }

  public async logDebug(
    error: Error,
    showInUi: boolean = false,
  ): Promise<void> {
    this.logger.debug(error);
    const message = this.getErrorMessage(error);
    await this.logDebugMessage(message, showInUi);
  }

  public async logDebugMessage(
    message: string,
    showInUi: boolean = false,
  ): Promise<void> {
    const level = "debug";
    const enrichedMessage = this.enrichMessage(message, level);
    this.logger.debug(enrichedMessage);
    await this.notifyUi(showInUi, enrichedMessage, level);
  }

  public async logInfo(error: Error, showInUi: boolean = false): Promise<void> {
    this.logger.info(error);
    const message = this.getErrorMessage(error);
    await this.logInfoMessage(message, showInUi);
  }

  public async logInfoMessage(
    message: string,
    showInUi: boolean = false,
  ): Promise<void> {
    const level = "info";
    const enrichedMessage = this.enrichMessage(message, level);
    this.logger.info(enrichedMessage);
    await this.notifyUi(showInUi, enrichedMessage, level);
  }

  public async logWarning(
    error: Error,
    showInUi: boolean = false,
  ): Promise<void> {
    this.logger.warn(error);
    const message = this.getErrorMessage(error);
    await this.logWarningMessage(message, showInUi);
  }

  public async logWarningMessage(
    message: string,
    showInUi: boolean = false,
  ): Promise<void> {
    const level = "warning";
    const enrichedMessage = this.enrichMessage(message, level);
    this.logger.warn(enrichedMessage);
    await this.notifyUi(showInUi, enrichedMessage, level);
  }

  public async logError(error: Error, showInUi: boolean = true): Promise<void> {
    this.logger.error(error); // Maybe we log double now, but the error might contain more than the message?
    const message = this.getErrorMessage(error);
    await this.logErrorMessage(message, showInUi);
  }

  public async logErrorMessage(
    message: string,
    showInUi: boolean = true,
  ): Promise<void> {
    const level = "error";
    const enrichedMessage = this.enrichMessage(message, level);
    this.logger.error(enrichedMessage);
    await this.notifyUi(showInUi, enrichedMessage, level);
  }

  public subscribeLogs(sub: uiLogSubscriber): void {
    this.uiNotifier.subscribe(this.uiTopic, sub);
  }

  private async notifyUi(
    showInUi: boolean,
    enrichedMessage: string,
    level: string,
  ) {
    if (showInUi) {
      await this.uiNotifier.publish(this.uiTopic, { enrichedMessage, level });
    }
  }

  private getErrorMessage(error: Error) {
    let message = error.toString();
    message += error.stack ? error.stack : "";

    if ((error as TechnicalError).additionalDetails) {
      message += "\n  " + (error as TechnicalError).additionalDetails;
    }
    return message;
  }

  private enrichMessage(message: string, level: string): string {
    const date = new Date().toLocaleString();
    const version = EnvironmentUtils.getFullVersion();
    const environment = EnvironmentUtils.getEnvironment();
    const accountHash = this.configurationManager.tryGetAccountFolder();

    return `${level.toUpperCase()} - ${date} - ${version} - ${environment} - ${accountHash}: ${message}${this.logMessageSuffix}`;
  }

  private static registeredUnhandledPromises = false;

  /* istanbul ignore next */ // Hard to test
  public static processUnhandledPromises() {
    if (this.registeredUnhandledPromises) {
      return;
    }

    if (BrowserOrNode.isBrowser() || BrowserOrNode.isWebWorker()) {
      window.onunhandledrejection = async (event) => {
        const error = new TechnicalError(
          "Unhandled promise rejection: " + event.reason,
        );
        await LogService.getInstance().logError(error);
      };
    }

    if (BrowserOrNode.isNode()) {
      process.on("unhandledRejection", async (error: any) => {
        await LogService.getInstance().logErrorMessage(
          "Unhandled promise rejection: ",
        );
        await LogService.getInstance().logError(error);
      });
    }
    this.registeredUnhandledPromises = true;
  }
}
