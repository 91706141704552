import { IIdentifiable } from "../domain/domainModels/IIdentifiable";
import { IRepository } from "../domain/IRepository";
import { OfflineService } from "../domain/OfflineService";
import { Notifier } from "../domain/pubsub/Notifier";
import { timestamp } from "../domain/Types";
import isNetworkError from "../lib/is-network-error";
import { LogService } from "../logging/LogService";
import { DependencyInjectionUtils } from "../util/DependencyInjectionUtils";
import { CachedRepository } from "./CachedRepository";

export class OfflineRepository<T extends IIdentifiable>
  extends CachedRepository<T>
  implements IRepository<T>
{
  constructor(
    protected override readonly logService: LogService,
    protected override readonly cacheRepo: IRepository<T>,
    protected override readonly dataRepo: IRepository<T>,
    protected override readonly notifier: Notifier,
    protected readonly offlineService: OfflineService,

    protected override initializeFromCache: boolean = false,
    protected override readonly updates?: () => Promise<void>,
  ) {
    // Remove last arguments from check because it is optional
    const xArguments = [...arguments];
    DependencyInjectionUtils.validateDependenciesDefined(
      xArguments.slice(0, -1),
    );

    super(
      logService,
      cacheRepo,
      dataRepo,
      notifier,
      initializeFromCache,
      updates,
    );
  }

  public override async getAll(): Promise<readonly T[]> {
    try {
      const result = await super.getAll();
      await this.offlineService.setOnline();
      return result;
    } catch (error) {
      if (isNetworkError(error)) {
        await this.offlineService.setOffline();
        return await this.cacheRepo.getAll();
      } else {
        throw error;
      }
    }
  }

  /* istanbul ignore next */ //Not used during integration
  public override async tryGetByUuid(uuid: string): Promise<T | undefined> {
    return await super.tryGetByUuid(uuid);
  }

  public override async save(model: T): Promise<timestamp> {
    // TODO: Implement buffer in localindexdb repo
    return await super.save(model);
  }

  public override async deleteAll(): Promise<timestamp> {
    // TODO: Return functionalerror when offline
    return await super.deleteAll();
  }

  /* istanbul ignore next */ // Not used as getTimestamp is for caching
  public override async getTimestamp(): Promise<timestamp> {
    // TODO: Return timestamp from cache when offline and detect netwerk errors to go offline
    return await super.getTimestamp();
  }

  // hard to test during integration
  /* istanbul ignore next */
  public override async delete(uuid: string): Promise<timestamp> {
    // TODO: Return functionalerror when offline
    return await super.delete(uuid);
  }

  public override async import(models: readonly T[]): Promise<timestamp> {
    // TODO: Return functionalerror when offline
    return await super.import(models);
  }
}
