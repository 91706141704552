import * as Uuid from "uuid";

import { timestamp } from "../Types";
import { Validate } from "../validators/Validate";
import { IDomainModel } from "./IDomainModel";

/* istanbul ignore next */ // only used in api and can't be tested with integration test
export class SessionTokenModel implements IDomainModel {
  public readonly uuid: string = Uuid.v4();
  public readonly modelVersion = 1;
  public readonly __type: string = "SessionTokenModel";

  public readonly token: string = "";
  public readonly timestamp: timestamp | null = null;

  protected static expectedProperties = [
    "modelVersion",
    "__type",
    "uuid",
    "token",
    "timestamp",
  ];

  constructor(init?: Partial<SessionTokenModel>) {
    this.uuid = Uuid.v4();
    Object.assign(this, init);
  }

  protected validateExpectedProperties() {
    Validate.expectedProperties(this, SessionTokenModel.expectedProperties);
  }

  public validate() {
    this.validateExpectedProperties();
    Validate.Uuidv4(this.uuid);
    Validate.number(this.modelVersion);
    Validate.stringNotEmpty(this.__type);
    // Validate.optionalTimestamp(this.timestamp); // TODO: implement
  }
}
